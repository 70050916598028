import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import Box from '../../base/Box';
import Button from '../Button';
import ArrowLeftIcon from '../../images/icons/arrow-left-icon.svg';
import ArrowRightIcon from '../../images/icons/arrow-right-icon.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Img from '../Img';

const Carousel = ({ carouselSx, controls, slides, settings }) => {
  const setControlSx = (direction) =>
    direction === 'next'
      ? {
          ...controls.controlNextSx,
        }
      : {
          ...controls.controlPrevSx,
        };
  const setControls = (direction) => {
    const isNext = direction === 'next';
    const isPrevious = direction !== 'next';
    return (
      <Button
        sx={{
          '& img': {
            maxHeight: '100%',
          },
          '&:before': {
            display: 'none',
          },
          '&:hover': {
            opacity: '1 !important',
          },
          bg: 'transparent',
          height: ['30px', null, null, '50px', null],
          left: `${isPrevious ? '0' : 'auto'}`,
          opacity: [null, null, null, '0'],
          p: 0,
          right: `${isNext ? '0' : 'auto'}`,
          transform: [
            'translateX(0)',
            null,
            null,
            `translateX(${isNext ? '100px' : '-100px'})`,
          ],
          transition: 'all .33s ease',
          width: ['30px', null, null, '50px', null],
          zIndex: '9',
          ...controls.controlsSx,
          ...setControlSx(direction),
        }}
      >
        <Img
          alt={direction}
          src={isNext ? controls.arrowRight : controls.arrowLeft}
        />
      </Button>
    );
  };

  const carouselStyled = {
    '&.slick-slider': {
      overflow: 'hidden',
    },
    '&.slick-slider:hover': {
      '.slick-next, .slick-prev': {
        opacity: '.8',
        transform: 'translateX(0)',
      },
    },
    '.slick-track': {
      '.slick-slide, .slick-slide > div': {
        display: 'flex',
        flex: 'auto',
        flexDirection: 'columns',
        height: 'auto',
      },
      alignItems: 'stretch',
      display: 'flex',
      justifyContent: 'center',
    },
    ...carouselSx,
  };

  const defaultSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'ease',
    infinite: true,
    nextArrow: setControls('next'),
    pauseOnHover: true,
    prevArrow: setControls('previous'),
    // speed: 1000,
    ...settings,
  };

  return (
    <Box as={Slider} sx={carouselStyled} {...defaultSettings}>
      {slides.length > 0 && slides.map((slide) => slide)}
    </Box>
  );
};

Carousel.propTypes = {
  carouselSx: PropTypes.shape({}),
  controls: PropTypes.shape({
    arrowLeft: PropTypes.string,
    arrowRight: PropTypes.string,
    controlNextSx: PropTypes.shape({}),
    controlPrevSx: PropTypes.shape({}),
    controlsSx: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({}),
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
};

Carousel.defaultProps = {
  carouselSx: {},
  controls: {
    arrowLeft: ArrowLeftIcon,
    arrowRight: ArrowRightIcon,
    controlNextSx: {},
    controlPrevSx: {},
    controlsSx: {},
  },
  settings: {},
};

export default Carousel;
